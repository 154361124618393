import "./singlePost.css"

export default function SinglePost(){
    return(
        <div className="singlePost">
            <div className="singlePostWrapper">
                <img 
                src="images/post1.jpg" 
                alt="" 
                className="singlePostImg" 
                />
                <h1 className="singlePostTitle">
                    Lorem ipsum dolor sit amet.
                    <div className="singlePostEdit">
                        <i className="singlePostIcon fa-regular fa-pen-to-square"></i>
                        <i className="singlePostIcon fa-solid fa-trash-can"></i>
                    </div>
                </h1>
                <div className="singlePostInfo">
                    <span className="singlePostAuthor">Author: <b>Rish</b></span>
                    <span className="singlePostDate">1 hour ago</span>
                </div>
                <p className="singlePostDesc">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam, 
                    fuga! Eaque odit minima sunt voluptas earum eos cupiditate vitae, 
                    vel tenetur quo dicta repudiandae eius, rerum quam odio
                    repellendus facere! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam, 
                    fuga! Eaque odit minima sunt voluptas earum eos cupiditate vitae, 
                    vel tenetur quo dicta repudiandae eius, rerum quam odio
                    repellendus facere!Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam, 
                    fuga! Eaque odit minima sunt voluptas earum eos cupiditate vitae, 
                    vel tenetur quo dicta repudiandae eius, rerum quam odio
                    repellendus facere!Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam, 
                    fuga! Eaque odit minima sunt voluptas earum eos cupiditate vitae, 
                    vel tenetur quo dicta repudiandae eius, rerum quam odio
                    repellendus facere!Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam, 
                    fuga! Eaque odit minima sunt voluptas earum eos cupiditate vitae, 
                    vel tenetur quo dicta repudiandae eius, rerum quam odio
                    repellendus facere!Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam, 
                    fuga! Eaque odit minima sunt voluptas earum eos cupiditate vitae, 
                    vel tenetur quo dicta repudiandae eius, rerum quam odio
                    repellendus facere!Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam, 
                    fuga! Eaque odit minima sunt voluptas earum eos cupiditate vitae, 
                    vel tenetur quo dicta repudiandae eius, rerum quam odio
                    repellendus facere!Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam, 
                    fuga! Eaque odit minima sunt voluptas earum eos cupiditate vitae, 
                    vel tenetur quo dicta repudiandae eius, rerum quam odio
                    repellendus facere!
                </p>
            </div>
        </div>
    )
}