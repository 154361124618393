import "./post.css"

export default function Post(){
    return(
        <div className="post">
            <img
            className="postImg"
            src="images/post1.jpg"
            alt=""
            />
            <div className="postInfo">
                <div className="postCats">
                    <span className="postCat">Python</span>
                    <span className="postCat">DS</span>
                </div>
                <span className="postTitle">
                    Lorem ipsum dolor sit amet
                </span>
                <hr/>
                <span className="postDate">1 hour ago</span>
            </div>
            <p className="postDesc">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                Sint qui provident distinctio totam. Obcaecati sapiente qui 
                vel quas quibusdam officiis quisquam, alias exercitationem 
                fugit, harum porro, deleniti modi sunt ab!
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                Sint qui provident distinctio totam. Obcaecati sapiente qui 
                vel quas quibusdam officiis quisquam, alias exercitationem 
                fugit, harum porro, deleniti modi sunt ab!
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                Sint qui provident distinctio totam. Obcaecati sapiente qui 
                vel quas quibusdam officiis quisquam, alias exercitationem 
                fugit, harum porro, deleniti modi sunt ab!
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                Sint qui provident distinctio totam. Obcaecati sapiente qui 
                vel quas quibusdam officiis quisquam, alias exercitationem 
                fugit, harum porro, deleniti modi sunt ab!
            </p>
        </div>
    )
}