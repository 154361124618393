import "./sidebar.css"

export default function Sidebar(){
    return(
        <div className="sidebar">
            <div className="sidebarItem">
                <span className="sidebarTitle">ABOUT ME</span>
                <img 
                className="postImages"
                src="images/post1.jpg"
                alt=""
                />
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia error 
                    dolorem quia asperiores nobis perferendis.
                </p>
            </div>
            <div className="sidebarItem">
            <span className="sidebarTitle">CATEGORIES</span>
            <ul className="sidebarList">
                <li className="sidebarListItem">Python</li>
                <li className="sidebarListItem">DS</li>
                <li className="sidebarListItem">Algorithms</li>
                <li className="sidebarListItem">Leetcode</li>
                <li className="sidebarListItem">Solutions</li>
                <li className="sidebarListItem">Projects</li>
            </ul>
            <div className="sidebarItem">
            <span className="sidebarTitle">FOLLOW US</span>
            <div className="sidebarSocial">
            <i className="sidebarIcon fa-brands fa-square-facebook"></i>
            <i className="sidebarIcon fa-brands fa-square-x-twitter"></i>
            <i className="sidebarIcon fa-brands fa-square-github"></i>
            <i className="sidebarIcon fa-brands fa-square-instagram"></i>
            </div>
            </div>
            </div>
        </div>
    )
}