import "./header.css";

export default function Header(){
    return(
        <div className="header">
            <div className="headerTitles">
                <span className="headerTitleSm">Human Code City</span>
                <span className="headerTitleLg">Blog</span>
            </div>
            <img className="headerImage" src="https://images.pexels.com/photos/3861972/pexels-photo-3861972.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt=""></img>
        </div>
    )
}